import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
import Login from "../views/Login.vue";
import NotFound from "../views/404.vue";
import Home from "../views/Home.vue";
import Main from "../views/Main.vue";
import Table from "../views/nav1/Table.vue";
import gate from "../views/nav1/gateOrder.vue";

let routes = [
  {
    path: "/login",
    component: Login,
    name: "",
    hidden: true,
  },
  {
    path: "/404",
    component: NotFound,
    name: "",
    hidden: true,
  },
  //{ path: '/main', component: Main },
  {
    path: "/",
    component: Home,
    name: "订单",
    iconCls: "el-icon-message", //图标样式class
    children: [
      { path: "/main", component: Main, name: "主页", hidden: true },
      { path: "/table", component: Table, name: "订单列表" },
      { path: "/gate", component: gate, name: "gate卡订单" },
    ],
  },
  // {
  //   path: "/",
  //   component: Home,
  //   name: "",
  //   iconCls: "fa fa-address-card",
  //   leaf: true, //只有一个节点
  //   children: [{ path: "/page6", component: Page6, name: "导航三" }],
  // },
  {
    path: "*",
    hidden: true,
    redirect: { path: "/404" },
  },
];

const router = new VueRouter({
  routes,
});
export default router;
