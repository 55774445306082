import Vue from "vue";
import App from "./App";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import store from "./vuex/store";
import Vuex from "vuex";
import router from "./router/index";
import axios from "./utils/axios";
import dayjs from 'dayjs';
import "font-awesome/css/font-awesome.min.css";

Vue.use(ElementUI);
Vue.use(Vuex);
Vue.prototype.$http = axios;
Vue.prototype.$dayjs = dayjs;



router.beforeEach((to, from, next) => {
  if (to.path == "/login") {
    localStorage.removeItem("token");
  }
  let user = localStorage.getItem("token");
  if (!user && to.path != "/login") {
    next({ path: "/login" });
  } else {
    if (to.path == "/") {
      next({ path: "/table" });
    }else{
      next();
    }
  }
});


new Vue({
  //el: '#app',
  //template: '<App/>',
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
