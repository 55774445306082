import instance from "./axios";


//登录
export const loginApi = (params) => {
  return instance.get('/api/login', { params });
};

//登出
export const logoutApi = (params) => {
  return instance.get('/api/logout', { params });
};

//订单列表
export const orderListApi = (params) => {
  return instance.get('/api/orderList', { params });
};

//gate卡订单
export const gateOrderListApi = (params) => {
  return instance.get('/api/gateOrderList', { params });
};
